'use client';

import { useEffect, useState } from 'react';

import { faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip, Input, ModalBody, ModalContent, ModalHeader, Radio, RadioGroup } from '@nextui-org/react';
import { useRouter, useSearchParams } from 'next/navigation';
import { useSession } from 'next-auth/react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import { CHAIN } from '@/app/ai-analytics/constants';
import CommonModal from '@/app/components/CommonModal';
import { IFilterData } from '@/types';

interface IFilterModal{
  open: boolean
  setOpen: (value: boolean) => void
}

const initialFilterValue: IFilterData = {
  time_stamp_end: new Date(),
  direction: 'ALL',
  currency: 'USD',
  chain: CHAIN.BITCOIN,
};

const getDateString = (date: Date) => {
  return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${('0' + date.getDate()).slice(-2)}`;
};

const addCommas = (num: string) =>
  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

const removeNonNumeric = (num: string) => num.toString().replace(/[^0-9]/g, '');

export function FilterModal({ open, setOpen }: IFilterModal){
  const router = useRouter();
  const searchParams = useSearchParams();
  const [data, setData] = useState<IFilterData | null>(initialFilterValue);
  const { data: session } = useSession();

  useEffect(() => {
    if (searchParams.toString()) {
      const filterData = Object.fromEntries(searchParams) as unknown as IFilterData;
      if (filterData.time_stamp_end) {
        let time_stamp_end = new Date((filterData.time_stamp_end as unknown as string).split('T')[0]);
        time_stamp_end = new Date(time_stamp_end.getTime() + (time_stamp_end.getTimezoneOffset() * 60000));
        filterData.time_stamp_end = time_stamp_end;
      }
      if (filterData.time_stamp_start) {
        let time_stamp_start = new Date((filterData.time_stamp_start as unknown as string).split('T')[0]);
        time_stamp_start = new Date(time_stamp_start.getTime() + (time_stamp_start.getTimezoneOffset() * 60000));
        filterData.time_stamp_start = time_stamp_start;
      }
      setData(filterData);
    }
  }, [searchParams]);

  const handleFire = ()=> {
    let _data: Record<string, any> = { ...data };
    Object.keys(_data).forEach(key => {
      if (_data[key] === undefined) {
        delete _data[key];
      }
    });
    const filter = { ..._data, time_stamp_end: '', time_stamp_start: '' };
    if (_data?.time_stamp_end) {
      filter.time_stamp_end = getDateString(_data.time_stamp_end) + 'T23:59:59.999Z';
    }
    if (data?.time_stamp_start) {
      filter.time_stamp_start = getDateString(_data.time_stamp_start) + 'T00:00:00.000Z';
    }
    router.push('/custom-search?' + new URLSearchParams(filter as unknown as Record<string, string>));

    setOpen(false);
  };

  const filterChange = (data: IFilterData) => {
    setData(data);
  };
  
  const onNumberChange = (field: string, value: string) => {
    setData({
      ...data,
      [field]: Number(removeNonNumeric(value)),
    } as IFilterData);
  };

  const handleClearFilter = () => {
    setData(initialFilterValue);
  };

  const onClose = () => {
    setOpen(false);
  };
  
  return (
    <CommonModal onESC={onClose} isOpen={open} size='4xl' closeButton={<></>} scrollBehavior='outside'>
      <ModalContent>
        <ModalHeader className='flex justify-end absolute right-1 top-1'>
          <div onClick={onClose} className='text-gray-400 bg-transparent hover:cursor-pointer hover:bg-slate-100 dark:hover:bg-slate-700 rounded-lg w-8 h-8 inline-flex justify-center items-center '>
            <svg className='w-3 h-3' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 14 14'>
              <path stroke='currentColor' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'/>
            </svg>
          </div>
        </ModalHeader>
        <ModalBody className="mt-5">
          <div className='space-y-6'>
            <div>
              <label className="block mb-2 font-medium">
                <span className='font-bold'>Select Chain</span>
              </label>
              <div className="flex">
                <RadioGroup
                  orientation='horizontal'
                  value={data?.chain}
                  className='mb-2'
                  onChange={(e) => filterChange({ ...data, chain: e.target.value } as IFilterData)}
                >
                  <Radio value={CHAIN.BITCOIN} className='z-0'><span className='font-bold'>Bitcoin</span></Radio>
                  <Radio value={CHAIN.COSMOS} className='z-0'><span className='font-bold'>Cosmos</span></Radio>
                </RadioGroup>
                <span className="ml-5">Additional chains coming soon</span>
              </div>
            </div>
            {data?.chain === CHAIN.COSMOS && (
              <div>
                <label className="block mb-2 font-medium">
                  <span className='font-bold'>Select Transaction Type(s)</span>
                </label>
                <div className="flex">
                  <RadioGroup
                    orientation='horizontal'
                    value={data?.transaction_type || 'ALL'}
                    onValueChange={(type: string) => filterChange({
                      ...data,
                      transaction_type: type === 'ALL' ? undefined : type
                    } as IFilterData)}
                    className='mb-2'
                  >
                    <Radio value='ALL' className='z-0'><span className='font-bold'>All</span></Radio>
                    <Radio value='Transfer' className='z-0'><span className='font-bold'>Send/Receive</span></Radio>
                    <Radio value='ClaimReward' className='z-0'><span className='font-bold'>Rewards</span></Radio>
                    <Radio value='Delegate' className='z-0'><span className='font-bold'>Delegations</span></Radio>
                    <Radio value='Undelegate' className='z-0'><span className='font-bold'>Undelegations</span></Radio>
                    <Radio value='Redelegate' className='z-0'><span className='font-bold'>Redelegations</span></Radio>
                  </RadioGroup>
                </div>
              </div>
            )}
            <div>
              {data?.time_stamp_end && data?.time_stamp_start &&
              data?.time_stamp_start > data?.time_stamp_end ?
                <Chip
                  startContent={<FontAwesomeIcon icon={faWarning}
                    className="fa-solid lg:w-[1.3rem] w-[1rem] inline-block pr-2 text-red"
                    style={{ color: 'red' }}/>}
                  variant='bordered'
                  color='warning'
                > Start date must be before end date, please correct.
                </Chip> : <></>
              }
              <div className='flex mt-0' id='date-range'>
                <div className='mr-2 w-[50%]'>
                  <label className=" font-bold block mr-2">Start Date</label>
                  <DatePicker
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 md:text-sm"
                    onChange={(date: Date | null) => filterChange({ ...data, time_stamp_start: date } as IFilterData)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select a date"
                    selected={data?.time_stamp_start}
                  />
                </div>
                <div className=' w-[50%]'>
                  <label className=" font-bold block mr-2 ">End Date</label>
                  <DatePicker
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 md:text-sm"
                    onChange={(date: Date | null) => filterChange({ ...data, time_stamp_end: date } as IFilterData)}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Select a date"
                    selected={data?.time_stamp_end}
                  />
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="address" className="block mb-2 font-medium">
                <span className='font-bold'>{data?.chain === CHAIN.BITCOIN ? 'Wallet Address' : 'Wallet or Validator Address'}</span>
              </label>
              <div className="flex items-center">
                <div className="w-3/5">
                  <Input
                    size="sm"
                    type="text"
                    name="address"
                    value={data?.address || ''}
                    id="address"
                    onChange={(e) => filterChange({ ...data, address: e.target.value } as IFilterData)}
                  />
                </div>
                <RadioGroup orientation='horizontal'
                  value={data?.direction || 'ALL'}
                  onValueChange={(direction: string) => filterChange({ ...data, direction } as IFilterData)}
                  className='mb-2 ml-3'
                >
                  {data?.chain === CHAIN.COSMOS && (
                    <Radio value='ALL' className='z-0'><span className='font-bold'>All</span></Radio>
                  )}
                  <Radio value='FROM' className='z-0'><span className='font-bold'>From</span></Radio>
                  <Radio value='TO' className='z-0'><span className='font-bold'>To</span></Radio>
                </RadioGroup>
              </div>
            </div>
            <div>
              <label htmlFor="other_address" className="block mb-2 font-medium">
                <span className='font-bold'>{data?.chain === CHAIN.BITCOIN ? 'Optional Wallet Address' : 'Optional Wallet or Validator Address'}</span>
              </label>
              <div className="flex items-center">
                <div className="w-3/5">
                  <Input
                    size="sm"
                    type="text"
                    name="other_address"
                    value={data?.other_address || ''}
                    id="other_address"
                    onChange={(e) => filterChange({ ...data, other_address: e.target.value } as IFilterData)}
                  />
                </div>
                <RadioGroup orientation='horizontal'
                  value={data?.other_direction || 'ALL'}
                  onValueChange={(other_direction: string) => filterChange({
                    ...data,
                    other_direction
                  } as IFilterData)}
                  className='mb-2 ml-3'
                >
                  <Radio isDisabled={data?.direction !== 'TO'} value='FROM' className='z-0'>
                    <span className='font-bold'>From</span>
                  </Radio>
                  <Radio isDisabled={data?.direction !== 'FROM'} value='TO' className='z-0'>
                    <span className='font-bold'>To</span>
                  </Radio>
                </RadioGroup>
              </div>
            </div>
            <div>
              <RadioGroup orientation='horizontal'
                value={data?.currency}
                onValueChange={(curr: string) => filterChange({ ...data, currency: curr } as IFilterData)}
                className='mb-2'
              >
                <Radio value='USD' className='z-0'><span className='font-bold'>USD</span></Radio>
                {data?.chain === CHAIN.BITCOIN ? (
                  <Radio value='BTC' className='z-0'><span className='font-bold'>BTC</span></Radio>
                ) : (
                  <Radio value='ATOM' className='z-0'><span className='font-bold'>ATOM</span></Radio>
                )}
              </RadioGroup>
              <div className='flex justify-between' id='amount'>
                <div className="mr-2 w-[50%]">
                  <Input
                    size="sm"
                    id="minimum-amount"
                    name="amount_min"
                    value={(data?.amount_min ? addCommas(data?.amount_min.toString()) : '') as any}
                    placeholder="minimum amount"
                    startContent={
                      data?.currency == 'USD' ?
                        <div className="pointer-events-none flex items-center">
                          <span className="text-default-400 text-small">$</span>
                        </div> :
                        data?.chain === CHAIN.BITCOIN ? 'BTC' : 'ATOM'
                    }
                    onChange={(e) => onNumberChange('amount_min', e.target.value)}
                  />
                </div>
                <div className="w-[50%]">
                  <Input
                    size="sm"
                    id="maximum-amount"
                    name='amount_max'
                    value={(data?.amount_max ? addCommas(data?.amount_max.toString()) : '') as any}
                    placeholder="maximum amount"
                    startContent={
                      data?.currency == 'USD' ?
                        <div className="pointer-events-none flex items-center">
                          <span className="text-default-400 text-small">$</span>
                        </div> :
                        data?.chain === CHAIN.BITCOIN ? 'BTC' : 'ATOM'
                    }
                    onChange={(e) => onNumberChange('amount_max', e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="tx-hash" className="block mb-2 font-medium">
                <span className='font-bold'>Transaction Hash</span>
              </label>
              <Input
                size="sm"
                type="text"
                id="tx-hash"
                name="txhash"
                value={data?.txhash ? data?.txhash : ''}
                placeholder='transaction hash'
                onChange={(e) => filterChange({ ...data, txhash: e.target.value } as IFilterData)}
              />
            </div>
            <div>
              <label htmlFor="tx-hash" className="block mb-2 font-medium">
                <span className='font-bold'>Block Number</span>
              </label>
              <Input
                size="sm"
                id="block"
                name="block"
                value={(data?.block ? addCommas(data?.block.toString()) : '') as any}
                placeholder='block number'
                onChange={(e) => onNumberChange('block', e.target.value)}
              />
            </div>
            {data?.chain === CHAIN.COSMOS && (
              <div>
                <label htmlFor="memo" className="block mb-2 font-medium">
                  <span className='font-bold'>Transaction Memo</span>
                </label>
                <Input
                  size="sm"
                  type="text"
                  id="memo"
                  value={data?.memo ? data?.memo : ''}
                  placeholder='transaction memo'
                  onChange={(e) => filterChange({ ...data, memo: e.target.value } as IFilterData)}
                />
              </div>
            )}
          </div>
          <div className='flex items-center py-6 space-x-2 rounded-b'>
            <button
              className="flex justify-center items-center font-medium go-btn bg-blue-500 hover:bg-blue-700 text-white p-2.5 min-w-[130px] rounded-[30px]"
              type="submit"
              onClick={handleFire}
            >
              <p className='text-base'>{session ? 'GO' : 'START FREE TRIAL'} &gt;</p>
            </button>
            <div onClick={handleClearFilter} data-modal-hide='staticModal'
              className='cursor-pointer text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium px-5 py-2.5 text-center rounded-[30px]'>Clear
            </div>
            <div onClick={() => setOpen(false)} data-modal-hide='staticModal'
              className='cursor-pointer text-white bg-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium px-5 py-2.5 text-center rounded-[30px]'>Cancel
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </CommonModal>);
}
