'use client';

import { useEffect, useState } from 'react';

import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableCell, TableColumn, TableHeader, TableRow } from '@nextui-org/react';
import { useRouter } from 'next/navigation';
import { useSession } from 'next-auth/react';
import { useDispatch, useSelector } from 'react-redux';

import { getCustomerPortalUrl, getUrl } from '@/services/stripe';
import { getPrices } from '@/services/stripe/price';
import { showLoading, hideLoading } from '@/store/loading';
import { selectUserPlan } from '@/store/user-plan/selectors';
import { Plan } from '@/types/subscription';

import No from './No';
import Yes from './Yes';

interface StripePrice {
  pro: string;
  business: string;
}

interface IPriceData {
  item: string;
  [Plan.STARTER]?: string | number;
  [Plan.PRO]?: string | number;
  [Plan.BUSINESS]?: string | number;
}

const pricingData = [
  {
    item: 'header',
  },
  {
    item: 'price',
    [Plan.STARTER]: 0,
    [Plan.PRO]: 29,
    [Plan.BUSINESS]: 159,
  },
  {
    item: 'Data Access Per Query',
    [Plan.STARTER]: '500 records/query',
    [Plan.PRO]: '5, 000 records/query',
    [Plan.BUSINESS]: '50, 000 records/query',
  },
  {
    item: 'Historical Data',
    [Plan.STARTER]: 'Last 6 months',
    [Plan.PRO]: 'Since 2/18/2021',
    [Plan.BUSINESS]: 'Since 2/18/2021',
  },
  {
    item: 'AI Analytics',
    [Plan.STARTER]: 'YES',
    [Plan.PRO]: 'YES',
    [Plan.BUSINESS]: 'YES',
  },
  {
    item: 'AI Analytics Visualization',
    [Plan.STARTER]: 'YES',
    [Plan.PRO]: 'YES',
    [Plan.BUSINESS]: 'YES',
  },
  {
    item: 'Custom Search',
    [Plan.STARTER]: 'YES',
    [Plan.PRO]: 'YES',
    [Plan.BUSINESS]: 'YES',
  },
  {
    item: 'My Bookmarks',
    [Plan.STARTER]: 'NO',
    [Plan.PRO]: 'YES',
    [Plan.BUSINESS]: 'YES',
  },
  {
    item: 'My Searches',
    [Plan.STARTER]: 'NO',
    [Plan.PRO]: 'YES',
    [Plan.BUSINESS]: 'YES',
  },
  {
    item: 'CSV Export',
    [Plan.STARTER]: 'NO',
    [Plan.PRO]: 'YES',
    [Plan.BUSINESS]: 'YES',
  },
  {
    item: 'buttonGroup',
  },
];

const columns = ['item', Plan.STARTER, Plan.PRO, Plan.BUSINESS];

type ColumnKey = typeof columns[number];

export function DataAccess(){
  const router = useRouter();
  const dispatch = useDispatch();
  const userPlan = useSelector(selectUserPlan);
  const [prices, setPrices] = useState<StripePrice>();
  const { status } = useSession();

  useEffect(()=> {
    if (status === 'authenticated') {
      getPrices().then((res) => {
        setPrices(res.data as StripePrice);
      });
    }
  },[status]);

  const handlePay = (priceId: string | undefined) => {
    if(!priceId) return;
    if (userPlan === Plan.STARTER) {
      dispatch(showLoading());
      getUrl(priceId)
        .then((res) => {
          router.push(res.data.url);
        })
        .catch((e)=> {
          alert(e.response.data.error);
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    } else {
      handleCancel(priceId);
    }
  };

  const handleCancel = (priceId: string | undefined) => {
    if(!priceId) return;
    dispatch(showLoading());
    getCustomerPortalUrl()
      .then(res => {
        router.push(res.data.url);
      })
      .catch((e)=> {
        console.log(e);
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

  function getCellClassName(row: IPriceData, columnKey: ColumnKey) {
    if (columnKey === 'item') {
      return 'w-3/12';
    }
    let className = 'text-center w-3/12';
    if (row['item'] === 'header') {
      className += ' bg-blue-500';
      if (columnKey === Plan.STARTER) className += ' rounded-ss-xl';
      if (columnKey === Plan.BUSINESS) className += ' rounded-se-xl';
    } else {
      if ([Plan.STARTER, Plan.BUSINESS].includes(columnKey as Plan)) className += ' bg-data-access-row1';

      if (['price', 'buttonGroup'].includes(row['item'])) {
        className += ' p-5';
      }

      if (row['item'] === 'buttonGroup' && [Plan.STARTER, Plan.BUSINESS].includes(columnKey as Plan)) {
        className += ' rounded-b-xl';
      }
    }
    return className;
  }

  return (
    <div className='md:flex w-full'>
      <Table hideHeader aria-label="Data access table">
        <TableHeader columns={columns}>
          {columns.map((column) => <TableColumn hideHeader key={column}>{column}</TableColumn>)}
        </TableHeader>
        <TableBody className="bg-white">
          {pricingData.map((row) => (
            <TableRow key={row.item}>
              {(columnKey) => (
                <TableCell className={getCellClassName(row, columnKey as ColumnKey)}>
                  {row.item === 'header' ? (
                    <>
                      {columnKey === 'item' ? '' : (
                        <>
                          <p>{columnKey}</p>
                          {
                            userPlan === Plan[columnKey as Plan] && (<FontAwesomeIcon icon={faCircleCheck} className="text-lg fa-solid text-blue-25"/>)
                          }
                        </>
                      )}
                    </>
                  ) : row.item === 'price' ? (
                    <>
                      {columnKey === 'item' ? '' : (
                        <>
                          <span className="text-3xl">
                              ${row[columnKey as Plan]}
                          </span>
                          <span>/mo</span>
                        </>
                      )}
                    </>
                  ) : row.item === 'buttonGroup' ? (
                    <>
                      {['item', Plan.STARTER].includes(columnKey as ColumnKey)
                        ? ''
                        : columnKey === Plan.PRO ? (
                          <>
                            {userPlan === Plan.STARTER
                              ? <button
                                className="font-medium go-btn text-white p-2.5 min-w-[130px] rounded-[30px] !text-medium"
                                onClick={()=> handlePay(prices?.pro)}
                              >
                                <p>Upgrade &gt;</p>
                              </button>
                              : userPlan === Plan.PRO
                                ? <button
                                  className="font-medium go-btn text-white p-2.5 min-w-[130px] rounded-[30px] !text-medium"
                                  onClick={()=> handleCancel(prices?.pro)}
                                >
                                  <p>Cancel</p>
                                </button>
                                : <></>
                            }
                          </>
                        ) : (
                          <>
                            {(userPlan === Plan.PRO || userPlan === Plan.STARTER)
                              ? <button
                                className="font-medium go-btn text-white p-2.5 min-w-[130px] rounded-[30px] !text-medium"
                                onClick={()=> handlePay(prices?.business)}
                              >
                                <p>Upgrade &gt;</p>
                              </button>
                              : <button
                                className="font-medium go-btn text-white p-2.5 min-w-[130px] rounded-[30px] !text-medium"
                                onClick={()=> handleCancel(prices?.business)}
                              >
                                <p>Cancel</p>
                              </button>
                            }
                          </>
                        )}
                    </>
                  ) : (
                    <>
                      {columnKey === 'item'
                        ? row['item']
                        : row[columnKey as Plan] === 'YES'
                          ? <Yes />
                          : row[columnKey as Plan] === 'NO'
                            ? <No />
                            : row[columnKey as Plan]}
                    </>
                  )}
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>);
}
