import { CHAIN } from '@/app/ai-analytics/constants';
import axios from '@/services/configs';

export const getBitcoinTx = async (txhash: string) => {
  let url = `/txs/bitcoin/${txhash}`;
  return axios.get(url);
};

export const search = async (chain: CHAIN, query: string) => {
  let url = `/txs/${chain.toLowerCase()}?` + query;
  return axios.get(url);
};

export const saveSearch = async (data: any) => {
  let url = '/searches';
  return axios.post(url, data);
};

export const getSavedSearch = async () => {
  return axios.get('/searches');
};

export const deleteSearch = async (search_id: string)  => {
  return axios.delete(`/searches/${search_id}`);
};
