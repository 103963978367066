import moment from 'moment';
import { DayValue } from 'react-modern-calendar-datepicker';

import { DURATIONS_KEY } from '@/types';

export const generateUniqueID = () => {
  return `${Date.now()}-${Math.floor(Math.random() * (9e12 - 1)) + 1e12}`;
};

export const diffTime = (value: number) => {
  const diffTimeArray = [
    { duration: 60, label: 'minutes' },
    { duration: 24, label: 'hours' },
    { duration: 30, label: 'days' },
    { duration: 12, label: 'months' },
    { duration: 1, label: 'years' },
  ];
  let diffId = 0;
  for (
    ;
    value > diffTimeArray[diffId].duration && diffId < 4;
    value /= diffTimeArray[diffId++].duration
  );
  return `${Math.floor(value)} ${diffTimeArray[diffId].label}`;
};

export const toggleArray = (array: string[], item: string) => {
  return array.includes(item) ? array.filter((e) => e !== item) : [...array, item];
};

export const formatCurrencyFraction = (value: number, prefix?: string) => {
  const rePrefix = prefix ?? '';
  return value
    ? value < 0.005
      ? `~${rePrefix}0`
      : `${rePrefix}${value.toLocaleString('en-us', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      })}`
    : `${rePrefix}0`;
};

export const formatCryptoValue = (value: number, decimal?: number) => {
  return value
    ? value.toLocaleString('en-us', {
      maximumFractionDigits: decimal ?? 5,
    })
    : '0';
};

export const formatWalletAddress = (walletAddress: string) => {
  if (walletAddress.length > 16)
    return `${walletAddress.slice(0, 10)}...${walletAddress.slice(-6)}`;
  return walletAddress;
};

export function formatFraction(
  value: number,
  prefix?: string,
  suffix?: string,
  decimal?: number,
  hasWhiteSpace?: boolean
) {
  return (
    (prefix || '') +
    (hasWhiteSpace ? ' ' : '') +
    (value
      ? value.toLocaleString('en-us', {
        maximumFractionDigits: decimal || 2,
        minimumFractionDigits: decimal || 2,
      })
      : '0') +
    (hasWhiteSpace ? ' ' : '') +
    (suffix || '')
  );
}

export const omitWord = (word: string, maxLength: number) => {
  if (word.length > maxLength) return `${word.slice(0, maxLength)}...`;
  return word;
};

export const getCryptoLogoUrl = (symbol: string) => {
  return `${process.env.REACT_APP_CLOUDINARY_URL}/icons/png/crypto/${symbol.toLowerCase()}.png`;
};

export const generateAvatar = (name: string) => {
  return name
    .split(' ')
    .map((val) => val.charAt(0))
    .join('');
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export function isEmpty(obj: { [key in any]: any }) {
  return Object.keys(obj).length === 0;
}

export function getTimeStamp(date: DayValue) {
  return moment(`${date?.year}/${date?.month}/${date?.day}`).toISOString();
}

export function setTimeSeriesInterval(
  time: string,
  index: number,
  duration: DURATIONS_KEY,
  length: number
) {
  switch (duration) {
  case 'daily':
    return index % 3 === 0 ? time : '';
  case 'monthly':
    return index % 7 === 0 ? moment(time).format('DD MMM') : '';
  case 'quarter':
    return index % 3 === 0 ? moment(time).format('DD MMM') : '';
  case 'semiyearly':
    return moment(time).format('MM/YYYY');
  case 'yearly':
    return index % 2 === 0 ? moment(time).format('MM/YYYY') : '';
  default:
    return time;
  }
}

export const displayDate = (str: string | undefined) => {
  if(!str) return '';
  const date_time = new Date(str);
  const date = date_time.toUTCString().slice(0, -13);
  const hours = date_time.getUTCHours() % 12;
  const mins = date_time.getMinutes();
  const amPm = date_time.getUTCHours() < 12 ? 'am' : 'pm';
  return `${date} ${hours}:${mins < 10 ? '0' + mins : mins}${amPm}`;
};

export const truncateText = (text: string | undefined, length: number = 10) => {
  if (text) {
    return length < 10 ? text : text.substring(0, 4) + '...' + text.substring(text.length - 4, text.length);
  }
  else return '';
};

export const truncateMemo = (memo: string) => {
  return memo.substring(0, 12) + '...';
};

export const getFromValue = (item: any) => {
  let retVal: string;
  switch (item.transactionType) {
  case 'Transfer':
    retVal = item.from_address;
    break;
  case 'Delegate':
    retVal = item.delegator_address;
    break;
  case 'ClaimReward':
    retVal = item.validator_address;
    break;
  case 'Redelegate':
    retVal = item.validator_src_address;
    break;
  case 'Undelegate':
    retVal = item.validator_address;
    break;
  default:
    retVal = item.from_address;
    break;
  }

  return retVal;
};

export const getToValue = (item: any) => {
  let retVal: string = '';
  switch (item.transactionType) {
  case 'Transfer':
    retVal = item.to_address;
    break;
  case 'ClaimReward':
    retVal = item.delegator_address;
    break;
  case 'Delegate':
    retVal = item.validator_address;
    break;
  case 'Redelegate':
    retVal = item.validator_dst_address;
    break;
  case 'Undelegate':
    retVal = item.delegator_address;
    break;
  default:
    retVal = item.to_address;
    break;
  }
  return retVal;
};

export async function getCurrentATOMPrice(): Promise<number | null> {
  try {
    // Using the CoinGecko API for this example.
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=cosmos&vs_currencies=usd');

    if (!response.ok) {
      throw new Error(`Error fetching price: ${response.status}`);
    }

    const data = await response.json();
    return data.cosmos.usd;
  } catch (error) {
    console.error('There was an error fetching the ATOM price:', error);
    return null;
  }
}

export const decorateNumber = (number: number, limit: number) => {
  if(!number) return '0';
  return number.toLocaleString(undefined, {
    minimumFractionDigits: limit,
    maximumFractionDigits: limit,
  });
};

export function isDate(value: any) {
  if (value instanceof Date) return true;
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(String(value))) return false;
  const d = new Date(value);
  return d instanceof Date && !isNaN(d.getTime()) && d.toISOString() === value;
}
